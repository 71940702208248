<template>
  <Box>
    
    <ErrorRow :error="error" />
    <SuccessRow :message="message" />
    <SessionTimeoutDialog v-if="showSessionTimeoutDialog" />
    
    <Row>
      <Column :width="9" :showBorder="true">
    
        <Box class="rc-text-medium rc-text-label">
          <Row>
            <Column>
              {{ MC.Filters.Title.value() }}
            </Column>
          </Row>
          
          <Row>
            <div class="rc-box-col-2 rc-text-middle">
              {{ MC.Filters.States.value() }}
            </div>
            
            <div class="rc-box-col-2">
              <Button v-on:click="setFilterState('all')" 
                :disabled="isFilterState('all')">
              {{ MC.States.All.value() }}
              </Button>
            </div>
            <div class="rc-box-col-2">
            </div>
            <div class="rc-box-col-2">
            </div>
            <div class="rc-box-col-2">
            </div>
            <div class="rc-box-col-5" />
          </Row>
          
        </Box>
        
      </Column>
      
      <Column :width="6" :showBorder="true">
        <Box class="rc-text-medium rc-text-label">
          <!--
          <Row>
            <Column>
              {{ MC.Actions.Title.value() }}
            </Column>
          </Row>
          
          <Row>
            <div class="rc-box-col-9" />

            <div class="rc-box-col-3" >
              <Button 
                v-on:click="add"
                :go="true"
                :disabled="false">
              {{ MC.Actions.Add.value() }}
              </Button>
            </div>
            
            <div class="rc-box-col-3">
              <Button v-on:click="refresh" :disabled="isLoading" >
              {{ MC.Actions.Refresh.value()}} 
              </Button>
            </div>
              
          </Row>
          -->
        </Box>
        
      </Column> <!-- col-6 -->
    </Row>

    <TitleRow>
      <Column :width="5">
        Name & Description
      </Column>
      <Column :width="1">
        Questions
      </Column>
      <Column :width="3">
        Created By/On
      </Column>
      <Column :width="3">
        Modified By/On
      </Column>
      <Column :width="1">
        State
      </Column>

      <Column :width="2">
      </Column>
    </TitleRow>
      
    <Row :showBorder="true"> <!-- row2 -->
      <Column :width="15">
        <Box>
          <Row v-for="(schedule, index) in schedules" :key="schedule.id()" :class="isEven(index) ? 'rowEven row' : 'rowOdd row'">
            <Column :width="15">
              <Box>
                <Row>
                  <Column :width="5">
                    <Value>
                      {{ schedule.name() }} <br>
                      {{ schedule.description() }}
                    </Value>
                  </Column>
                    
                  <Column :width="1">
                    <Value>
                      {{ schedule.questions().size() }}
                    </Value>
                  </Column>
                    
                  <Column :width="3">
                    <Value>
                      {{ schedule.createdBy().find().fullName() }}<br>
                      {{ schedule.createdDateV2().displayAs_YYYY_MM_DD() }}
                    </Value>
                  </Column>
                    
                  <Column :width="3">
                    <Value>
                      {{ schedule.modifiedBy().find().fullName() }} <br>
                      {{ schedule.createdDateV2().displayAs_YYYY_MM_DD() }}
                    </Value>
                  </Column>
                    
                  <Column :width="1">
                    <Value>
                      {{ schedule.state() }}
                    </Value>
                  </Column>
    
                  <Column :width="2">
                    <Value>
                      
                    </Value>
                  </Column>
                </Row>
                <MenuRow :isAdmin="isAdmin" :scheduleId="schedule.id()" />
                    
              </Box>
                    
            </Column>
          </Row>
              
              
        </Box> <!-- /box-15 -->
      </Column> <!-- /row2 -->
    </Row>
    
    <LoadingRow :showBorder="true" v-if="isLoading">{{loadingMessage}}</LoadingRow>

    <Row :showBorder="true" v-if="!isLoading" >
      <div class="rc-box-col-4" />
      <div class="rc-box-col-1">
        <Button v-if="canPreviousPage()" v-on:click="previousPage"> &lt; </Button>
      </div>
      
      <div class="rc-box-col-5 rc-text-centre rc-text-label">
        Showing {{ min }} to {{ max }}  of {{ total }}
      </div>

      <div class="rc-box-col-1">
        <Button v-if="canNextPage()" v-on:click="nextPage"> &gt; </Button>
      </div>
      <div class="rc-box-col-4" />
    </Row>
    
  </Box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MC from "@/domain/session/MC.js";
import ConnectionUtils from '@/utils/ConnectionUtils.js';

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import ErrorRow    from '@/components/row/ErrorRow.vue';
import SuccessRow  from "@/components/row/SuccessRow.vue";


import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Button from "@/portals/shared/library/button/Button.vue";
import Value from "@/portals/shared/library/value/Value.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  
import MenuRow from "./MenuRow.vue";

import ScheduleMap from "@/domain/model/schedule/ScheduleMap.js";
import SessionTimeoutDialog from "@/portals/shared/views/session/SessionTimeoutDialog.vue";

export default {
  name: 'portal-customer-operator-schedule-list',
  components: {
    Button,
    ErrorRow,
    SuccessRow,
    Value,
    Box, Row, Column,
    TitleRow, LoadingRow,
    MenuRow, SessionTimeoutDialog,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
    isAdminList: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      displayProgress: false,
      
      isLoading: false,
      loadingMessage: "",

      MC: new MC(),
      
      total: 0,
      page: 0,
      pageSize: 10,
      pageNumber: 0,

      stateFilter: 'all',
      schedules: [],
      
      showSessionTimeoutDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
    ]),
    max: function() {
      var t = (this.pageNumber + 1) * this.pageSize;
      return this.total < t ? this.total : t;
    },
    min: function() {
      if (this.total == 0) {
        return 0;
      }
      return (this.pageNumber * this.pageSize) + 1;
    },
    company: function () {
      return this.domain.session().company();
    },
    hasFeatureSso: function () {
      return this.company.features().find().allowsSso();
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.message = null;
      this.loadingMessage = "";
      this.loadData();
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    
    isEven: function (position) {
      return StringUtils.isEven(position);
    },
    
    decode: function (value) {
      return ContentUtils.unescape(value);
    },
    
    loadData: function() {
      if (this.isLoading) {
        return;
      }
      this.schedules = [];
      this.isLoading = true;
      this.error = null;
      this.loadingMessage = this.MC.Schedule.Status.Loading.value();
      
      var event = this.domain.events().schedules().list();
      event.send(this.loadSchedulesListener);
    },
    
    loadSchedulesListener: function(response) {
      if (response.error()) {
        this.error = response.error();
      } else {
        var payload = response.payload();
        var values = payload.valuesFor(ScheduleMap.MODEL_NAME);
        this.schedules = new ScheduleMap(this.domain, values).sort();
      }
      this.isLoading = false;
    },
    
    refresh: function () {
      this.schedules = [];
      this.loadData();
    },
    
    add: function () {
      //
    },
    
    details: function(scheduleId) {
      if (scheduleId) {
        var params = {
            id: scheduleId,
          };
          
          this.$router.push({
            name: ConstUtils.ROUTES.SCHEDULE.DETAILS,
            params: params,
          });
      }
    },
    
    edit: function(scheduleId) {
      if (!scheduleId) {
        return;
      }
      var params = {
          id: scheduleId,
        };
        
        this.$router.push({
          name: ConstUtils.ROUTES.SCHEDULE.EDIT,
          params: params,
        });
    },
    
    isFilterState(state) {
      return this.stateFilter === state;
    },
    
    setFilterState(state) {
      this.stateFilter = state;
      this.pageNumber = 0;
      this.loadUsersByFilter();
    },
    
    previousPage: function() {
      if (this.canPreviousPage()) {
        this.pageNumber--;
        this.loadUsersByFilter();
      }
    },
    
    canPreviousPage: function() {
      return this.pageNumber > 0;
    },
    
    canNextPage: function() {
      return this.total > (this.pageSize * this.pageNumber) + this.pageSize;
    },

    nextPage: function() {
      if (this.canNextPage()) {
        this.pageNumber++;
        this.loadUsersByFilter();
      }
    },
  },
  
};
</script>
